import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Breadcrumbs} from "../Breadcrumbs/Breadcrumbs";
import {Headline1, Headline2, Headline3, Headline5} from "../../components/Headlines/Headlines";
import {Subtitle2, Subtitle3} from "../../components/Subtitles/Subtitles";
import {Body} from "../../components/Texts/Texts";
import {Button, IconButton, Label} from "../../components/Buttons/Buttons";
import {useClickInside} from "../../hooks/useClickInside";
import {Spinner} from '../../components/Spinner/Spinner';
import {useSideDrawerContext} from '../../contexts/SideDrawerContext';
import {Await, Link, useFetcher, useParams} from 'react-router-dom';
import {ProductAvailability} from '../../components/ProductAvailability/ProductAvailability';
import {useProductsInCartContext} from '../../contexts/ProductsInCartContext';
import {ProductFlag} from '../../components/ProductFlag/ProductFlag';
import {ReactComponent as Synchronize} from '../../assets/icons/synchronize.svg';
import {ReactComponent as ShoppingCart} from "../../assets/icons/shopping-cart.svg";
import {ReactComponent as Favorite} from "../../assets/icons/favorite-outline.svg";
import {ReactComponent as ChevronDown} from "../../assets/icons/chevron-down.svg";
import {ReactComponent as Check} from "../../assets/icons/check.svg";
import {ReactComponent as Close} from "../../assets/icons/close.svg";
import {ReactComponent as SecondHand} from '../../assets/icons/second-hand.svg';
import placeholder from '../../assets/images/placeholder.jpg';
import {sendEcommerceEvent} from '../../events/dataLayer';
import IsReserved from "../../helpers/SecondHandHelper";

// Main
const StyledProductHeader = styled.section``;

const Outer = styled.div`
    padding: 20px 20px 0 20px;
    position: relative;
    max-width: 1400px;
    margin: 0 auto;

    @media screen and (max-width: 820px) {
        padding: 0;
    }
`;

const Inner = styled.div`
    background: var(--color-primary);
    background: linear-gradient(180deg, var(--color-primary) 80%, transparent 80%);
    padding: 40px 0 0;
    
    @media screen and (max-width: 820px) {
        background: linear-gradient(180deg, var(--color-primary) 70%, transparent 70%);
        
        ${Container} { padding: 0 20px; }
    }

    @media screen and (max-width: 480px) {
        background: linear-gradient(180deg, var(--color-primary) 50%, transparent 50%);
    }
`;

const Grid = styled.div`
    margin-top: 40px;
    display: grid;
    grid-template-columns: 250px 1fr 1fr;
    grid-template-rows: auto 1fr 1fr;
    grid-template-areas: 
        "image title title"
        "image list box"
        "interaction empty box";

    @media screen and (max-width: 820px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 
            auto
            1fr
            60px
            auto;
        grid-template-areas: 
            "title title"
            "image box"
            "empty box"
            "list list"
        ;
    }
    
    @media screen and (max-width: 480px) {
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas: 
            "title"
            "image"
            "box"   
            "list"
        ;
    }
`;

// Image
const ImageItem = styled.div`
    grid-area: image;
    align-self: end;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media screen and (max-width: 820px) {
        align-items: flex-start;
        margin-bottom: 25px;
    }

    @media screen and (max-width: 480px) {
        align-items: center;
        margin-bottom: 30px;
    }
`;

const MainImageWrapper = styled.div`
    position: relative;
`;

const BookImage = styled.img`
    width: 100%;
    max-height: 383px;
    object-fit: contain;
    object-position: bottom;
    display: block;

    @media screen and (max-width: 820px) {
        max-width: 230px;
    }
    
    @media screen and (max-width: 480px) {
        width: auto;
        max-height: 238px;
    }
`;

const BookShelf = styled.hr`
    border: 1px solid var(--color-primary-60);
    position: absolute;
    bottom: 0;
    right: -600px;
    margin: 0;
    width: 100vw;
    
    @media screen and (max-width: 1024px) {
        right: -450px;
    }
    
    @media screen and (max-width: 820px) {
        left: -100px;
    }

    @media screen and (max-width: 480px) {
        left: -20px;
        right: unset;
        width: 100%;
    }
`;

// Interaction
const ImageInteractionItem = styled.div`
    grid-area: interaction;
    align-self: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &.hidden {
        display: none;
    }
    
    @media screen and (max-width: 820px) {
        display: none;
    }
`;

const FlipButton = styled.button`
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 10px 0;
    
    path { fill: var(--color-white) }
`;

const BookPreviews = styled.div`
    display: flex;
    gap: 10px;
    align-items: flex-start;
`;

const BookPreview = styled.img`
    cursor: pointer;
    max-width: 90px;
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: top;
    display: block;
`;

// Title
const TitleItem = styled.div`
    grid-area: title;
    margin-left: 64px;
    margin-bottom: 30px;
    
    ${Subtitle2}, ${Headline1} { margin-bottom: 5px; }

    @media screen and (max-width: 820px) {
        ${Headline1} { font-size: 40px; }
        margin-left: 0;
    }
`;

// List
const ListItem = styled.div`
    grid-area: list;
    margin-left: 64px;
    margin-right: 30px;
    margin-bottom: 30px;
    
    ${Headline3} { 
        margin-bottom: 16px;
        display: none;
    }
    
    ${Body} { margin: 0; }
    
    @media screen and (max-width: 820px) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;

        ${Headline3} { display: block; }
        ${Body} { color: var(--color-primary); } 
    }
`;

// Box
const BoxItem = styled.div`
    grid-area: box;
    align-self: start;
    justify-self: end;
    width: 100%;

    @media screen and (max-width: 820px) {
        align-self: end;
        margin-left: 30px;
        width: unset;
    }

    @media screen and (max-width: 480px) {
        align-self: unset;
        justify-self: unset;
        margin-bottom: 30px;
        margin-left: 0;
    }
`;

const BuyBox = styled.div`
    max-width: 400px;
    width: 100%;
    background-color: var(--color-white);
    border: 1px solid var(--color-primary-10);
    padding: 20px;
    z-index: 1;
    position: relative;
    
    ${Headline5},${Headline2}{ margin-bottom: 15px; }
    ${Headline2}{ color: var(--color-secondary); }

    @media screen and (max-width: 820px) {
        max-width: unset;
    }
`;

const Options = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    min-height: 30px;

    ${Label} {
        display: inline;
        width: auto;
        max-width: unset;
    }

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

const BuyActions = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    
    ${IconButton} { flex-shrink: 0; }
`;

const DropdownWrapper = styled.div`
    position: relative;
`;

const DropdownField = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--color-primary-10);
    padding: 0 10px 0;
    
    ${Body} { margin: 0; }
`;

const StyledChevronDown = styled(ChevronDown)`
    path { fill: var(--color-primary) }
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    
    ${({$open}) => $open && 'transform: rotate(180deg)'};
`;

const DropdownInner = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 5px;
    padding: ${({$noPadding}) => $noPadding ? '0' : '10px'};
    background-color: var(--color-white);
    border: 1px solid var(--color-primary-10);
`;

const Center = styled.div`
    padding: ${({$noPadding}) => $noPadding ? '0' : '10px'};
    text-align: center;
`;

const Shop = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    
    ${Body} { margin: 0; }
`;

const SecondHandOption = styled(Body)`
    padding: 4px 10px;
    background-color: ${({$active}) => $active ? 'var(--color-white)' : 'var(--color-primary-10)'};
    text-decoration: none;
    
    &:hover {
        background-color: var(--color-white);
    }
`;

const StyledCheck = styled(Check)`
    flex-shrink: 0;
    path { fill: var(--color-success) }
`;

const StyledClose = styled(Close)`
    flex-shrink: 0;
    path { fill: var(--color-danger) }
`;

const SecondHandState = styled.div`
    margin-top: 4px;
    min-height: 30px;
`;

const RatingLabel = styled.div`
    display: flex;
    align-items: center;
    
    ${Body} { margin: 0; }
`;

const Message = styled(Body)`
    margin: 4px 0;
`;

const StyledSecondHand = styled(SecondHand)`
    width: 23px;
    height: 23px;
    
    path {
        stroke: var(--color-primary);
    }
`;

export const ProductHeader = ({data, shops, types}) => {
    const {isbnsWithGiftwrapInCart} = useProductsInCartContext();
    const {openDrawer, closeDrawer} = useSideDrawerContext();

    const bookImageRef = useRef(null);
    const frontPreviewRef = useRef(null);
    const backPreviewRef = useRef(null);
    const [front, setFront] = useState(true);
    const [hasBack, setHasBack] = useState(null);
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        if(hasBack && frontPreviewRef.current && backPreviewRef.current) {
            backPreviewRef.current.style.maxHeight = `${frontPreviewRef.current.offsetHeight}px`;
        }
    }, [hasBack]);

    const fetcher = useFetcher();
    const params = useParams();

    const isSecondHand = Boolean(params?.eanf);
    const isEbook = Boolean(data?.product?.eBook);

    let addingToWishlist = (fetcher.formMethod === "post" && fetcher.state !== 'idle');
    let deleting = (fetcher.formMethod === "delete" && fetcher.state !== 'idle');

    const handleWishlistClick = async () => {
        const formData = new FormData();
        formData.append("isbn", data?.product?.isbn);
        fetcher.submit(formData, { method: 'POST', action: '/verlanglijstje'})

        sendEcommerceEvent('add_to_wishlist', [data?.product]);
    }

    const handleCartClick = async () => {
        const formData = new FormData();
        formData.append("isbn", data?.product?.isbn);
        if(isbnsWithGiftwrapInCart?.includes(data?.product?.isbn)) formData.append("giftwrap", 128); // Add giftwrap, if it is already in cart with giftwrap
        if(isSecondHand) formData.append("eanf", params?.eanf); // Add eanf if it is a secondHand product
        fetcher.submit(formData, { test: closeDrawer('Cart'), method: 'POST', action: '/winkelmandje'})

        sendEcommerceEvent('add_to_cart', [data?.product]);
    }

    useEffect(() => {
        if(fetcher.data) {
            openDrawer(fetcher.formAction === '/verlanglijstje' ? 'Wishlist' : 'Cart')
        }
        // eslint-disable-next-line
    }, [fetcher.data])

    return (
        <StyledProductHeader>
            <Outer>
                <Inner>
                    <Container>
                        <Breadcrumbs $variant='primary' data={data?.breadcrumbs} />

                        <Grid>
                            <ImageItem>
                                <MainImageWrapper>
                                    <BookImage ref={bookImageRef} src={imageError ? placeholder : (front ? data?.product?.image : data?.product?.imageBack)} alt={data?.product?.title} onLoad={() => { if (bookImageRef.current) { bookImageRef.current.style.maxHeight = `${bookImageRef.current.offsetHeight}px`; } }} onError={() => setImageError(true)} />
                                    {(isSecondHand || isEbook) &&
                                        <ProductFlag flag={isSecondHand ? "secondHand" : "ebook"}  />
                                    }
                                </MainImageWrapper>
                                <BookShelf/>
                            </ImageItem>

                            <ImageInteractionItem className={hasBack ? 'visible' : 'hidden'}>
                                <FlipButton onClick={() => setFront(front => !front)}>
                                    <Synchronize/> <Subtitle2 $white>Boek omdraaien</Subtitle2>
                                </FlipButton>

                                <BookPreviews>
                                    <BookPreview ref={frontPreviewRef} src={data?.product?.image} onClick={()=> setFront(true)} />
                                    <BookPreview ref={backPreviewRef} src={data?.product?.imageBack} onLoad={() => setHasBack(true) } onClick={()=> setFront(false)}/>
                                </BookPreviews>
                            </ImageInteractionItem>

                            <TitleItem>
                                <Subtitle2 $white>{data?.product?.author}</Subtitle2>
                                <Headline1 $white>{data?.product?.title}</Headline1>
                                <Subtitle3 $white>{data?.product?.subtitle}</Subtitle3>
                            </TitleItem>

                            <ListItem>
                                <Headline3>Details</Headline3>
                                {Object.entries(data?.product?.specs).map(([key, value], index) => {
                                    if (value === null || value === "") return null;
                                    return <Body key={index} $white><strong>{key + ": "}</strong>{value}</Body>;
                                })}
                            </ListItem>

                            <BoxItem>
                                <BuyBox>
                                    <Headline5>Boektype</Headline5>
                                    <Options>
                                        {isSecondHand ? (
                                            <Label as="button" $disabled $hugged $variant="primary">Tweedehands<React.Suspense><Await resolve={types} errorElement={<></>}>{(typesData) => <> {typesData?.types?.find(item => item.secondhand === true).price}</>}</Await></React.Suspense></Label>
                                        ) : (
                                            <Label as="button" $disabled $hugged $variant="primary">{data?.product?.productForm} {data?.product?.price ?? "Fout"}</Label>
                                        )}
                                        {types &&
                                            <React.Suspense fallback={<Spinner $small />}>
                                                <Await resolve={types} errorElement={<></>}>
                                                    {(typesData) => (
                                                        <>
                                                            {typesData?.types?.map((item, index) => {
                                                                if (!isSecondHand && item.isbn === data?.product?.isbn) return null;
                                                                if (isSecondHand && item.secondhand === true) return null;
                                                                return <Label key={index} to={`/${item.url}`} $hugged $variant="outline">{item.productForm} {item.price ?? "Fout"}</Label>
                                                            })}
                                                        </>
                                                    )}
                                                </Await>
                                            </React.Suspense>
                                        }
                                    </Options>

                                    {isSecondHand &&
                                        <React.Suspense fallback={<Spinner $small $block />}>
                                            <Await resolve={types} errorElement={<></>}>
                                                {(typesData) => (
                                                    <SecondHandDropdown data={typesData?.conditions} params={params} />
                                                )}
                                            </Await>
                                        </React.Suspense>
                                    }

                                    <Headline2>{data?.product?.price ?? "Fout"}</Headline2>

                                    <React.Suspense>
                                        <Await resolve={Promise.all([shops, types])} errorElement={<></>}>
                                            {([shopsData, typesData] = data) => {
                                                return (
                                                    (!IsReserved(typesData) && (shopsData?.productAvailabilityType === "available" || shopsData?.productAvailabilityType === "upcoming")) &&
                                                    <BuyActions>
                                                        <Button as="button" onClick={handleCartClick} disabled={addingToWishlist || deleting} $variant="secondary" $icon><ShoppingCart /> In winkelmandje</Button>
                                                        <IconButton as="button" onClick={handleWishlistClick} disabled={addingToWishlist || deleting} $variant="outline"><Favorite /></IconButton>
                                                    </BuyActions>
                                                )
                                            }}
                                        </Await>
                                    </React.Suspense>

                                    <ProductAvailability data={[shops, types]} $margin />

                                    {!isEbook &&
                                        <>
                                            <Headline5>Winkelvoorraad</Headline5>
                                            <ShopsDropdown shops={shops} />
                                        </>
                                    }
                                </BuyBox>
                            </BoxItem>
                        </Grid>
                    </Container>
                </Inner>
            </Outer>
        </StyledProductHeader>
    );
}

const SecondHandDropdown = ({data, params}) => {
    const popupRef = useRef(null);
    const [open, setOpen] = useState(false);
    const inside = useClickInside(popupRef);

    useEffect(() => {
        if(open && !inside) {
            setOpen(false);
        }
    }, [open, inside]);

    if(data?.error || !data?.length) return null;

    const activeCondition = data?.find(item => item.eanf === JSON.parse(params.eanf));

    return (
        <>
            {data?.length > 1 && (
                <DropdownWrapper ref={popupRef}>
                    <DropdownField onClick={() => setOpen(open => !open)}>
                        <Body>Tweedehands {activeCondition?.price} - {activeCondition?.ratingLabel}</Body>
                        <StyledChevronDown $open={open} />
                    </DropdownField>
                    {open &&
                        <DropdownInner $noPadding>
                            {data?.map((item, index) => {
                                if(JSON.parse(params.eanf) === item.eanf) return null;
                                return (
                                    <SecondHandOption key={index} as={Link} to={`/product/${params?.isbn}/${params?.title}/${item.eanf}`} onClick={() => setOpen(false)}>
                                        Tweedehands {item.price} - {item.ratingLabel}
                                    </SecondHandOption>
                                )
                            })}
                        </DropdownInner>
                    }
                </DropdownWrapper>
            )}

            <SecondHandState>
                <RatingLabel>
                    <StyledSecondHand/><Body $bold $small>{activeCondition?.ratingLabel}</Body>
                </RatingLabel>
                {activeCondition?.message && <Message $small>{activeCondition?.message}</Message>}
            </SecondHandState>
        </>
    );
}

const ShopsDropdown = ({shops}) => {
    const popupRef = useRef(null);
    const [open, setOpen] = useState(false);
    const inside = useClickInside(popupRef);

    useEffect(() => {
        if(open && !inside) {
            setOpen(false);
        }
    }, [open, inside]);

    return (
        <DropdownWrapper ref={popupRef}>
            <DropdownField onClick={() => setOpen(open => !open)}>
                <Body>Bekijk de winkelvoorraad</Body>
                <StyledChevronDown $open={open} />
            </DropdownField>
            {open &&
                <DropdownInner>
                    <React.Suspense fallback={<Center $noPadding><Spinner $small/></Center>}>
                        <Await resolve={shops} errorElement={<></>}>
                            {(shopsData) => (
                                <>
                                    {shopsData?.error && <Body $small $noMargin>Het is niet gelukt om de winkelvoorraad op te halen. Probeer het later opnieuw.</Body>}
                                    {shopsData?.shops?.map((shop, index) => (
                                        <Shop key={index}>
                                            {shop?.stock ? <StyledCheck/> : <StyledClose/>}
                                            <Body $bold={shop?.stock}>{shop?.name}</Body>
                                        </Shop>
                                    ))}
                                </>
                            )}
                        </Await>
                    </React.Suspense>
                </DropdownInner>
            }
        </DropdownWrapper>
    );
}