import {useParams} from "react-router-dom";

export default function IsReserved (data)  {
    const params = useParams();
    const isSecondHand = Boolean(params?.eanf);

    let reserved = false;

    if (isSecondHand) {
        const activeCondition = data?.conditions?.find(item => item.eanf === JSON.parse(params?.eanf));
        reserved = activeCondition?.reserved;
    }

    return reserved;
}