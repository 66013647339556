import {Spacing} from "../../components/Spacing/Spacing";
import styled from "styled-components";
import {Container} from "../../components/Container/Container";
import {CustomLink} from "../../components/CustomLink/CustomLink";
import {AspectRatio} from "../../components/AspectRatio/AspectRatio";

const StyledImageGallery = styled(Container)``;

const ImageContainer = styled.div`
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 475px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const DesktopAspectRatio = styled(AspectRatio)`
    @media screen and (max-width: 475px) {
        display: none;
    }
`;

const ResponsiveAspectRatio = styled(AspectRatio)`
    @media screen and (min-width: 476px) {
        display: none;
    }
`;

export const ImageGallery = ({data}) => {
    if (!data?.imageGallery || data?.imageGallery?.length < 3) {
        return null;
    }

    return (
        <Spacing>
            <StyledImageGallery>
                <ImageContainer>
                    {data?.imageGallery?.map((image, index) => (
                        <CustomLink key={index} to={image?.url} linkType={image?.linkType}>
                            {Boolean(image?.imageURL) &&
                                <DesktopAspectRatio
                                    src={image?.imageURL}
                                    alt=""
                                    ratioWidth={1}
                                    ratioHeight={1}
                                />
                            }
                            {Boolean(image?.responsiveImageURL) &&
                                <ResponsiveAspectRatio
                                    src={image?.responsiveImageURL}
                                    alt=""
                                    ratioWidth={4}
                                    ratioHeight={3}
                                />
                            }
                        </CustomLink>
                    ))}
                </ImageContainer>
            </StyledImageGallery>
        </Spacing>
    )
}