import {PageHeaderSlider} from '../../sections/PageHeaderSlider/PageHeaderSlider';
import {ContentSlider} from '../../sections/ContentSlider/ContentSlider';
import {Tips} from '../../sections/Tips/Tips';
import {Banner} from '../../sections/Banner/Banner';
import {ContentItem} from '../../sections/ContentItem/ContentItem';
import {DirectToLinks} from '../../sections/DirectToLinks/DirectToLinks';
import {DirectToTagCloud} from '../../sections/DirectToTagCloud/DirectToTagCloud';
import {EmployeeSlider} from '../../sections/EmployeeSlider/EmployeeSlider';
import {HeaderBanner} from '../../sections/HeaderBanner/HeaderBanner';
import {HeaderSearch} from '../../sections/HeaderSearch/HeaderSearch';
import {Newsletter} from '../../sections/Newsletter/Newsletter';
import {ProductSlider} from '../../sections/ProductSlider/ProductSlider';
import {Review} from '../../sections/Review/Review';
import {FAQ} from '../../sections/FAQ/FAQ';
import {TextBlock} from '../../sections/TextBlock/TextBlock';
import {AgendaBlock} from '../../sections/AgendaBlock/AgendaBlock';
import {Media} from '../../sections/Media/Media';
import {ConditionalWrap} from '../ConditionalWrap/ConditionalWrap';
import styled from 'styled-components';
import {Giftcard} from '../../sections/Giftcard/Giftcard';
import {ProductRows} from '../../sections/ProductRows/ProductRows';
import {ImageBannerHeader} from '../../sections/ImageBannerHeader/ImageBannerHeader';
import {ImageGallery} from '../../sections/ImageGallery/ImageGallery';

const sectionComponents = {
    "agenda-group": AgendaBlock,
    "banner": Banner,
    "banner-header": HeaderBanner,
    "content-item": ContentItem,
    "content-slider": ContentSlider,
    "direct-to-link": DirectToLinks,
    "direct-to-link-with-tagcloud": DirectToTagCloud,
    "employee-slider": EmployeeSlider,
    "faq": FAQ,
    "giftcard": Giftcard,
    "header-search": HeaderSearch,
    "image-banner-header": ImageBannerHeader,
    "image-gallery": ImageGallery,
    "media": Media,
    "newsletter": Newsletter,
    "page-header-slider": PageHeaderSlider,
    "product-rows": ProductRows,
    "product-tab-slider": ProductSlider,
    "review": Review,
    "tips": Tips,
    "text": TextBlock,
}

const Spacing = styled.div`
    padding-top: 60px;
    
    @media screen and (max-width: 1024px) {
        padding-top: 30px;
    }
`;

// Display a section individually
const Block = ({ sectionData, breadcrumbData, isFirst }) => {
    // Prepare the component
    const SectionComponent = sectionComponents[sectionData.type]

    if (!SectionComponent) { return null }

    // Add extra spacing to all blocks except this array, if it is the first one in the list
    const typesAllowedAsHeader = ["page-header-slider", "banner", "banner-header", "header-search", "image-banner-header"];

    // Display the section
    return (
        <ConditionalWrap condition={isFirst && !typesAllowedAsHeader.includes(sectionData.type)} wrap={children => <Spacing>{children}</Spacing>}>
            <SectionComponent data={sectionData} breadcrumbs={breadcrumbData} />
        </ConditionalWrap>
    )
}

// Display the list of sections
export const Blocks = ({ data, breadcrumbs }) => {
    if(!data) { return null }

    return (
        <>
            {data.map((section, index) => (
                <Block key={`${section.type}${index}`} sectionData={section} breadcrumbData={breadcrumbs} isFirst={index === 0} />
            ))}
        </>
    )
}